import { ReactFlowProvider } from 'reactflow'

import { Metadata } from '@redwoodjs/web'

import InformerOrgChartCell from 'src/components/InformerOrgChart/InformerOrgChartCell'
import PageHeader from 'src/components/PageHeader/PageHeader'
import useOrgChartFlowStore from 'src/lib/stores/orgChartFlowStores'

const InformerOrgChartPage = ({ id }) => {
  const structureView = useOrgChartFlowStore((state) => state.structureView) // State of if the user is in structure view mode

  return (
    <>
      <Metadata title="Organisation Chart" description="Organisation Chart" />
      <PageHeader
        title={`Organisation Chart${structureView ? ' - Planning Mode' : ''}`}
        parentDataTestId="org-chart-page-header"
      />
      <ReactFlowProvider>
        <InformerOrgChartCell structureId={id} />
      </ReactFlowProvider>
    </>
  )
}

export default InformerOrgChartPage

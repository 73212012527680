import {
  CreateOrgChartLayoutMutation,
  CreateOrgChartLayoutMutationVariables,
  FindInformerOrgChartQuery,
  SoftDeleteOrgChartLayoutMutation,
  SoftDeleteOrgChartLayoutMutationVariables,
  UpdateOrgChartLayoutMutation,
  UpdateOrgChartLayoutMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import InformerOrgChart from 'src/components/InformerOrgChart/InformerOrgChart'
import { default as EmptyComponent } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { useAuth } from 'src/Providers'

const membershipsToIgnore = [
  'Windmill',
  'Service',
  'Baserow',
  'Stafflink',
  'Staging',
  'Production',
] // Substrings to ignore

// Get all the memberships for the basic org chart and the structures for the advanced org chart
export const QUERY = gql`
  query FindInformerOrgChartQuery {
    membershipGroups: membershipGroups {
      id
      name
    }
    memberships: membershipsByClient(activeOnly: true) {
      id
      user {
        id
        name
        position
        avatarUrl
      }
      memberPositions {
        id
        name
        description
        responsibilities
        measurables
      }
      reportsTo {
        id
        user {
          name
        }
      }
      clientId
      isActive
    }

    dbList: orgChartLayouts {
      id
      orgChartData
      name
      version
      clientId
      published
      createdByUser {
        id
        name
      }
      aclPrincipals {
        id
        resourceId # The id of the org chart layout
        principalType # The type of principal (MEMBERSHIP, GROUP)
        principalId # The id of the principal
      }
    }
  }
`

// Create a new org chart layout
export const CREATE_ORGCHARTLAYOUT_MUTATION = gql`
  mutation CreateOrgChartLayoutMutation($input: CreateOrgChartLayoutInput!) {
    createOrgChartLayout(input: $input) {
      orgChartData
      name
    }
  }
`

// Soft delete a chart layout
export const DELETE_ORGCHARTLAYOUT_MUTATION = gql`
  mutation SoftDeleteOrgChartLayoutMutation($id: Int!) {
    deleteOrgChartLayout(id: $id) {
      id
    }
  }
`

// Update the org chart layout
export const UPDATE_ORGCHARTLAYOUT_MUTATION = gql`
  mutation UpdateOrgChartLayoutMutation(
    $id: Int!
    $input: UpdateOrgChartLayoutInput!
  ) {
    updateOrgChartLayout(id: $id, input: $input) {
      orgChartData
      published
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  return (
    <div className="flex h-[calc(100vh-64px)] ">
      <div className="grow p-1 grid place-items-center">
        <EmptyComponent
          title="No Organisation Chart Data Available"
          description="Create some members to populate your organisation chart"
        />
      </div>
    </div>
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: ({
  memberships,
  dbList,
  structureId,
  membershipGroups,
}: {
  memberships: FindInformerOrgChartQuery['memberships']
  dbList: FindInformerOrgChartQuery['dbList']
  structureId: number
  membershipGroups: FindInformerOrgChartQuery['membershipGroups']
}) => JSX.Element = ({
  memberships,
  dbList,
  structureId,
  membershipGroups,
}) => {
  const filteredMemberships = memberships.filter((membership) => {
    // Check if the membership's user name includes any of the substrings to ignore
    return !membershipsToIgnore.some((substring) =>
      membership.user.name.includes(substring),
    )
  })

  const activeMemberships = filteredMemberships.filter((membership) => {
    return membership.isActive
  })

  const [updateOrgChartLayout] = useMutation<
    UpdateOrgChartLayoutMutation,
    UpdateOrgChartLayoutMutationVariables
  >(UPDATE_ORGCHARTLAYOUT_MUTATION, {
    onCompleted: () => {
      toast.success('Saved')
    },
    onError: (createError) => {
      toast.error(createError.message)
    },
    refetchQueries: ['FindInformerOrgChartQuery'],
    awaitRefetchQueries: true,
  })

  const [createOrgChartLayout] = useMutation<
    CreateOrgChartLayoutMutation,
    CreateOrgChartLayoutMutationVariables
  >(CREATE_ORGCHARTLAYOUT_MUTATION, {
    onCompleted: () => {
      toast.success('Generated')
    },
    onError: (createError) => {
      toast.error(createError.message)
    },
    refetchQueries: ['FindInformerOrgChartQuery'],
    awaitRefetchQueries: true,
  })

  const [deleteOrgChartLayout] = useMutation<
    SoftDeleteOrgChartLayoutMutation,
    SoftDeleteOrgChartLayoutMutationVariables
  >(DELETE_ORGCHARTLAYOUT_MUTATION, {
    onCompleted: () => {
      toast.success('Deleted')
    },
    onError: (deleteError) => {
      toast.error(deleteError.message)
    },
    refetchQueries: ['FindInformerOrgChartQuery'],
    awaitRefetchQueries: true,
  })
  const { currentUser } = useAuth()
  const currentUserGroupIds =
    currentUser?.membershipData?.membershipGroups?.map((group) => {
      return group.id
    })
  const currentUserId = currentUser?.userData?.id
  const currentMembershipId = currentUser?.membershipData?.id

  const isSupportMode = currentUser.isClientAlias

  const enrichedDbList = dbList.map((db) => {
    const allowedUserId =
      db?.aclPrincipals?.length > 0
        ? db?.aclPrincipals?.find((principal) => {
            return (
              principal?.principalType === 'MEMBERSHIP' &&
              principal?.principalId === currentMembershipId
            )
          })
        : null
    const allowedGroupIds =
      db?.aclPrincipals?.length > 0
        ? db?.aclPrincipals?.filter((principal) => {
            return (
              principal?.principalType === 'MEMBERSHIPGROUP' &&
              currentUserGroupIds?.includes(principal?.principalId)
            )
          })
        : []

    const allSharedGroupIds =
      db?.aclPrincipals?.length > 0
        ? db?.aclPrincipals?.filter((principal) => {
            return principal?.principalType === 'MEMBERSHIPGROUP'
          })
        : []

    const allowedGroupNames = membershipGroups
      .map((group) => {
        const foundGroup = allSharedGroupIds.find((allowedGroup) => {
          return allowedGroup.principalId === group.id
        })
        return foundGroup ? group.name : null
      })
      .filter((name) => name !== null)

    const allowedMemberNames =
      db?.aclPrincipals?.length > 0
        ? db.aclPrincipals
            .map((principal) => {
              const foundMember = activeMemberships.find((membership) => {
                return membership.id === principal.principalId
              })
              return foundMember ? foundMember.user.name : null
            })
            .filter((name) => name !== null) // Filter out null values
        : []

    const isAllowedToEdit =
      isSupportMode ||
      allowedUserId ||
      allowedGroupIds?.length > 0 ||
      currentUserId === db.createdByUser.id

    return {
      ...db,
      isAllowedToEdit,
      groupsThatCanEdit: allowedGroupNames,
      membersThatCanEdit: [...allowedMemberNames, db.createdByUser.name],
    }
  })

  return (
    <InformerOrgChart
      createOrgChartLayout={createOrgChartLayout}
      updateOrgChartLayout={updateOrgChartLayout}
      deleteOrgChartLayout={deleteOrgChartLayout}
      structureId={structureId}
      memberships={activeMemberships}
      dbList={enrichedDbList}
    />
  )
}
export type UpdateOrgChartLayoutType = ReturnType<
  typeof useMutation<
    UpdateOrgChartLayoutMutation,
    UpdateOrgChartLayoutMutationVariables
  >
>[0]
export type CreateOrgChartLayoutType = ReturnType<
  typeof useMutation<
    CreateOrgChartLayoutMutation,
    CreateOrgChartLayoutMutationVariables
  >
>[0]
export type DeleteOrgChartLayoutType = ReturnType<
  typeof useMutation<
    SoftDeleteOrgChartLayoutMutation,
    SoftDeleteOrgChartLayoutMutationVariables
  >
>[0]

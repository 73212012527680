import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import 'reactflow/dist/style.css'
import {
  ArrowsPointingOutIcon,
  ArrowUpIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  DocumentPlusIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  ShareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { LinkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { Divider, FormControl, Select, Tooltip } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { getHubUrl } from 'api/src/common/utils'
import { useReactFlow } from 'reactflow'

import { toast } from '@redwoodjs/web/toast'

import DebounceTextInput from 'src/components/DebounceTextInput'
import InformerOrgChartDownloadButton from 'src/components/InformerOrgChart/InformerOrgChartDownloadButton'
import Button from 'src/components/Library/Button/Button'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import useOrgChartFlowStore from 'src/lib/stores/orgChartFlowStores'
import { useAuth } from 'src/Providers'

interface InformerOrgChartMenuBarProps {
  deleteBusinessStructure: () => void
  createBusinessStructure: (fromBusinessStructure: boolean) => void
  duplicateBusinessStructure: () => void
  deleteStructureLoading: boolean
  duplicateStructureLoading: boolean
  structureList: { id: number; value: string }[]
  currentStructure: number
  structureTitle: string
  addStructureLoading: boolean
  togglePublishedStructure: () => void
  setShareModalOpen: Dispatch<SetStateAction<boolean>>
  published: boolean
  planOwner: { id: number; name: string }
  isAllowedToEdit: boolean
}

const InformerOrgChartMenuBar = ({
  deleteBusinessStructure,
  createBusinessStructure,
  duplicateBusinessStructure,
  deleteStructureLoading,
  duplicateStructureLoading,
  structureList,
  currentStructure,
  structureTitle,
  addStructureLoading,
  togglePublishedStructure,
  published,
  planOwner,
  setShareModalOpen,
  isAllowedToEdit,
}: InformerOrgChartMenuBarProps) => {
  const searchText = useOrgChartFlowStore((state) => state.searchText) // State of the search text
  const setSearchText = useOrgChartFlowStore((state) => state.setSearchText) // Function to set the state of searchText
  const setFullView = useOrgChartFlowStore((state) => state.setFullView) // Function to set the state of fullView
  const structureView: boolean = useOrgChartFlowStore(
    (state) => state.structureView,
  ) // State of if the user is in structure view mode
  const setStructureView = useOrgChartFlowStore(
    (state) => state.setStructureView,
  ) // Function to set the state of structureView

  const { currentUser, hasRole } = useAuth()
  const isAdminAccess = hasRole(['ADMIN', 'OWNER', 'EDITOR', 'SUPERADMIN'])

  const reactFlow = useReactFlow()

  const confirmDeleteStructure = useConfirm()
  const confirmTogglePublish = useConfirm()

  const [actionMode, setActionMode] = useState<string>('live')

  useEffect(() => {
    if (actionMode === 'live') {
      setStructureView(false)
    }
    if (actionMode === 'planning') {
      setStructureView(true)
    }
  }, [actionMode])

  const [viewMode, setViewMode] = useState<string>('detailed')

  useEffect(() => {
    if (viewMode === 'compact') {
      setFullView(false)
    }
    if (viewMode === 'detailed') {
      setFullView(true)
    }
  }, [viewMode])

  return (
    <div className="p-10 pb-0 pt-4 w-full max-w-7xl mx-auto relative z-10">
      <div className="flex bg-white px-6 py-2 gap-4 items-center justify-between border border-gray-300 rounded-full">
        <div className="flex gap-4 items-center">
          <div className="flex items-center gap-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
            <DebounceTextInput
              placeholder="Search"
              name={'searchText'}
              onChange={setSearchText}
              debounceTimeout={1500}
              defaultValue={searchText}
              setKey={searchText}
              className="p-0"
            />
          </div>
          {actionMode === 'planning' && (
            <>
              <Divider orientation="vertical" flexItem className="my-3" />
              <div className="flex items-center gap-1">
                <Tooltip title={'Add New Plan'}>
                  <div>
                    <Button
                      fullWidth={false}
                      loading={addStructureLoading}
                      className="min-w-[0] p-2 rounded-full text-gray-500"
                      variant="text"
                      onClick={() => {
                        createBusinessStructure(false)
                      }}
                    >
                      <DocumentPlusIcon className="w-5 h-5" />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip title={'Duplicate Plan'}>
                  <div>
                    <Button
                      fullWidth={false}
                      loading={duplicateStructureLoading}
                      className="min-w-[0] p-2 rounded-full text-gray-500"
                      variant="text"
                      onClick={duplicateBusinessStructure}
                      disabled={!isAllowedToEdit}
                    >
                      <DocumentDuplicateIcon className="w-5 h-5" />
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip title={'Delete Plan'}>
                  <div>
                    <Button
                      fullWidth={false}
                      loading={deleteStructureLoading}
                      className="min-w-[0] p-2 rounded-full text-gray-500"
                      variant="text"
                      onClick={() => {
                        confirmDeleteStructure({
                          title: 'Delete plan?',
                          description:
                            'Are you sure you want to delete this plan?',
                        }).then(() => {
                          deleteBusinessStructure()
                        })
                      }}
                      disabled={!isAllowedToEdit}
                    >
                      <TrashIcon className="w-5 h-5" />
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </>
          )}

          <>
            <Divider orientation="vertical" flexItem className="my-3" />

            <div className="flex items-center gap-1">
              {structureList && structureList?.length > 0 && (
                <Tooltip title={'Copy Link To Share'}>
                  <div>
                    <Button
                      fullWidth={false}
                      loading={deleteStructureLoading}
                      className="min-w-[0] p-2 rounded-full text-gray-500"
                      variant="text"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `${getHubUrl()}/app/people/org-chart/${currentStructure}`,
                          )
                          .then(() => {
                            toast.success('Copied to clipboard')
                          })
                          .catch(() => {
                            toast.error('Failed to copy to clipboard')
                          })
                      }}
                    >
                      <LinkIcon className="w-5 h-5" />
                    </Button>
                  </div>
                </Tooltip>
              )}

              {actionMode === 'planning' && (
                <Tooltip title={'Share'}>
                  <div>
                    <Button
                      fullWidth={false}
                      loading={deleteStructureLoading}
                      className="min-w-[0] p-2 rounded-full text-gray-500"
                      variant="text"
                      onClick={() => {
                        setShareModalOpen(true)
                      }}
                      disabled={!isAllowedToEdit}
                    >
                      <ShareIcon className="w-5 h-5" />
                    </Button>
                  </div>
                </Tooltip>
              )}
              {structureList && structureList?.length > 0 && (
                <InformerOrgChartDownloadButton orgChartName={structureTitle} />
              )}
              {actionMode === 'planning' && (
                <Tooltip
                  title={
                    planOwner?.id === currentUser?.userData?.id
                      ? `${published ? 'Un-Publish' : 'Publish'} Plan`
                      : 'Only the owner can publish/un-publish this plan'
                  }
                >
                  <div>
                    <Button
                      fullWidth={false}
                      disabled={planOwner?.id !== currentUser?.userData?.id}
                      className={`min-w-[0] p-2 rounded-full text-gray-500 ${
                        published ? 'bg-green-100' : ''
                      }`}
                      variant="text"
                      onClick={() => {
                        confirmTogglePublish({
                          title: published
                            ? 'Un-publish plan?'
                            : 'Publish plan?',
                          description: published
                            ? 'Unpublishing the plan will remove this org chart from being visible to the rest of the business. Are you sure you want to un-publish this plan?'
                            : 'Publishing the plan will make this org chart visible to the rest of the business. Are you sure you want to publish this plan?',
                        }).then(() => {
                          togglePublishedStructure()
                        })
                      }}
                    >
                      {published ? (
                        <CheckIcon className="w-5 h-5 text-green-600" />
                      ) : (
                        <ArrowUpIcon className="w-5 h-5" />
                      )}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
          </>
          <Divider orientation="vertical" flexItem className="my-3" />
          <div className="flex items-center gap-1">
            <Tooltip title={'Fit chart to view'}>
              <div>
                <Button
                  fullWidth={false}
                  variant="text"
                  className="min-w-[0] p-2 rounded-full text-gray-500"
                  onClick={() => reactFlow.fitView()}
                >
                  <ArrowsPointingOutIcon className="w-5 h-5" />
                </Button>
              </div>
            </Tooltip>
            <Tooltip title={'Zoom in'}>
              <div>
                <Button
                  fullWidth={false}
                  variant="text"
                  className="min-w-[0] p-2 rounded-full text-gray-500"
                  onClick={() => {
                    reactFlow.zoomIn()
                  }}
                >
                  <MagnifyingGlassPlusIcon className="w-5 h-5" />
                </Button>
              </div>
            </Tooltip>
            <Tooltip title={'Zoom out'}>
              <div>
                <Button
                  fullWidth={false}
                  variant="text"
                  className="min-w-[0px] p-2 rounded-full text-gray-500"
                  onClick={() => {
                    reactFlow.zoomOut()
                  }}
                >
                  <MagnifyingGlassMinusIcon className="w-5 h-5" />
                </Button>
              </div>
            </Tooltip>
            <FormControl size="small">
              <Select
                className="w-[130px] text-gray-500 text-sm p-1 pb-0 pt-[8px]"
                disableUnderline
                id="org-chart-view-mode-select"
                variant="standard"
                value={viewMode}
                onChange={(e) => {
                  setViewMode(e.target.value)
                }}
                sx={{
                  '& .MuiSelect-select': {
                    background: 'white!important',
                  },
                }}
              >
                <MenuItem value={'compact'}>Compact View</MenuItem>
                <MenuItem value={'detailed'}>Detailed View</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider orientation="vertical" flexItem className="my-3" />
        </div>
        <div className="flex gap-4 items-center">
          {isAdminAccess && (
            <div className="flex items-center gap-1">
              <FormControl size="small">
                <Select
                  className="w-[130px] text-gray-500 text-sm p-1 pb-0 pt-[8px]"
                  disableUnderline
                  id="org-chart-action-mode-select"
                  variant="standard"
                  value={actionMode}
                  onChange={(e) => {
                    setActionMode(e.target.value)
                  }}
                  sx={{
                    '& .MuiSelect-select': {
                      background: 'white!important',
                    },
                  }}
                >
                  <MenuItem value={'live'}>Live Mode</MenuItem>
                  <MenuItem value={'planning'}>Planning Mode</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InformerOrgChartMenuBar

import React, { useEffect } from 'react'

import 'reactflow/dist/style.css'
import { TableName } from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import Modal from 'src/components/Modal/Modal'
import ToastMessage from 'src/components/ToastMessage/ToastMessage'
import useACL from 'src/lib/hooks/ACL/useACL'

const InformerOrgChartSharingModal = ({
  openModal,
  handleCloseModal,
  planOwner,
  structureTitle,
  currentStructure,
}) => {
  // Init ACL
  const {
    AccessControlList,
    saveACL,
    inputChangedACL,
    isLoading: isLoadingACL,
  } = useACL({
    resourceType: 'OrgChartLayout' as TableName,
    resourceId: currentStructure,
    principalTypes: ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
    contentPrivateDefault: true,
  })

  useEffect(() => {
    const saveACLChanges = async () => {
      await saveACL({
        resourceId: currentStructure,
      })
    }

    if (!isLoadingACL && inputChangedACL) {
      saveACLChanges().catch(() => {
        toast.error(
          <ToastMessage message="There was an error modifying the permissions of this plan" />,
          {
            duration: 5000,
            className: 'flex-column',
          },
        )
      })
    }
  }, [inputChangedACL])

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      title={'Share this plan'}
      className="max-w-3xl"
      footerVisible
      onCancel={handleCloseModal}
      onConfirm={handleCloseModal}
    >
      <div className="p-6">
        <p className="pb-4">
          <span className="font-bold">{structureTitle}&nbsp;</span>
          is owned by <span className="text-indigo-600">{planOwner?.name}</span>
        </p>

        <p className="pb-6">
          Due to the potential sensitivity of business structure planning, only
          the plan owner and specified members/groups are able to access the
          structure plan.
        </p>
        <AccessControlList
          membershipCardText={''}
          hideModalToggle={true}
          membershipCardTitle={'Share with other Groups or Memberships'}
        />
      </div>
    </Modal>
  )
}

export default InformerOrgChartSharingModal

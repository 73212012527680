import create from 'zustand'

const useOrgChartFlowStore = create((set) => ({
  fullView: true,
  setFullView: (value) => set({ fullView: value }),
  isAllowedToEdit: false,
  setIsAllowedToEdit: (value) => set({ isAllowedToEdit: value }),

  membersThatCanEdit: [],
  setMembersThatCanEdit: (value) => set({ membersThatCanEdit: value }),

  groupsThatCanEdit: [],
  setGroupsThatCanEdit: (value) => set({ groupsThatCanEdit: value }),

  availableUsers: [],
  setAvailableUsers: (value) => set({ availableUsers: value }),
  allUsers: [],
  setAllUsers: (value) => set({ allUsers: value }),
  allocatedUsers: [],
  setAllocatedUsers: (value) => set({ allocatedUsers: value }),
  allPositions: [],
  setAllPositions: (value) => set({ allPositions: value }),
  viewFromPosition: 'ORG_NODE',
  setViewFromPosition: (value) => set({ viewFromPosition: value }),

  viewFromPositionChartMode: 'ORG_NODE',
  setViewFromPositionChartMode: (value) =>
    set({ viewFromPositionChartMode: value }),

  addSeatLoading: false,
  setAddSeatLoading: (value) => set({ addSeatLoading: value }),
  deleteSeatAllocationLoading: false,
  setDeleteSeatAllocationLoading: (value) =>
    set({ deleteSeatAllocationLoading: value }),
  deleteSeatLoading: false,
  setDeleteSeatLoading: (value) => set({ deleteSeatLoading: value }),
  editNode: null,
  setEditNode: (value) => set({ editNode: value }),
  searchText: '',
  setSearchText: (value) => set({ searchText: value }),

  structureView: false,
  setStructureView: (value) => set({ structureView: value }),

  expandedNodes: [],
  setExpandedNodes: (value) => set({ expandedNodes: value }),
}))

export default useOrgChartFlowStore

import { FC } from 'react'

import {
  ArrowUturnUpIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  EyeSlashIcon,
  PencilIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { Popover, Tooltip } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import Button from '../Library/Button/Button'

interface SeatNodeSubMenuProps {
  data: any // not typed
  structureView: boolean
  allPositions: any // not typed
  handleMoveToNewSupervisor: (event: any) => void
  handleSelectChangePopover: (event: any) => void
  handleClosePopover: (event: any) => void
  setEditNode: (event: any) => void
  selectedAnchorPosition: any // not typed
  anchorEl: any // not typed
  popoverOpen: boolean
  popoverId: string
  viewFromPosition: any // not typed
  setViewFromPosition: any // not typed
  isAllowedToEdit: boolean
}

const SeatNodeSubMenu: FC<SeatNodeSubMenuProps> = ({
  data,
  structureView,
  allPositions,
  handleMoveToNewSupervisor,
  handleSelectChangePopover,
  handleClosePopover,
  selectedAnchorPosition,
  anchorEl,
  popoverOpen,
  popoverId,
  setEditNode,
  setViewFromPosition,
  viewFromPosition,
  isAllowedToEdit,
}) => {
  return (
    <div className="border-2 border-gray-300 rounded-full px-1 p-2 bg-white cursor-default flex flex-col gap-1">
      {structureView && (
        <>
          {/* // Move to new supervisor button */}
          <Tooltip placement={'left'} title={'Move To New Supervisor'}>
            <div>
              <Button
                fullWidth={false}
                disabled={!isAllowedToEdit}
                variant="text"
                className="min-w-[0] p-2 rounded-full rounded-full text-gray-500"
                onClick={handleMoveToNewSupervisor}
              >
                <ArrowUturnUpIcon className="w-5 h-5" />
              </Button>
            </div>
          </Tooltip>
          {/* // Popover for the select box to allow the user to select the new
          supervisor */}
          <Popover
            className="nodrag"
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <Autocomplete
              style={{
                height: '60px',
                width: '250px',
                padding: '10px',
              }}
              value={
                allPositions.find((pos) => pos.id === selectedAnchorPosition) ||
                null
              }
              onChange={(_event, newValue) => {
                handleSelectChangePopover(newValue)
              }}
              options={allPositions.filter((item) => {
                if (item.id === data.id) return false // Filter out items where data.id is item.id

                if (data.type === 'ent') {
                  return item.type === 'ent' || item.type === 'org' // Only allow 'ent' and 'org' when data.type is 'ent'
                }

                return true // Return all other items if data.type is not "ent"
              })}
              getOptionLabel={(option) => option.position || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Position"
                  size="small"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '10px', // Set font size for input box
                    },
                  }}
                />
              )}
              fullWidth
              renderOption={(props, option) => (
                <li {...props}>
                  <MenuItem
                    style={{ fontSize: '10px' }} // Set the font size for dropdown items
                  >
                    {option.position}
                  </MenuItem>
                </li>
              )}
            />
          </Popover>
          <Tooltip placement={'left'} title={'Duplicate Seat'}>
            <div>
              <Button
                fullWidth={false}
                disabled={!isAllowedToEdit}
                variant="text"
                className="min-w-[0] p-2 rounded-full rounded-full text-gray-500"
                onClick={() => {
                  data.addSeatToStructureFunction(data.id, data.type, true)
                  setEditNode(null)
                }}
              >
                <DocumentDuplicateIcon className="w-5 h-5" />
              </Button>
            </div>
          </Tooltip>
          <Tooltip placement={'left'} title="Edit the seat">
            <div>
              <Button
                fullWidth={false}
                disabled={!isAllowedToEdit}
                variant="text"
                className="min-w-[0] p-2 rounded-full rounded-full text-gray-500"
                onClick={() => {
                  setEditNode(data)
                }}
              >
                <PencilIcon className="w-5 h-5" />
              </Button>
            </div>
          </Tooltip>
        </>
      )}
      {(viewFromPosition === 'ORG_NODE' || data.id === viewFromPosition) && (
        <Tooltip
          placement={'left'}
          title={
            viewFromPosition === 'ORG_NODE'
              ? `Focus On ${data.position}`
              : 'Return To Full View'
          }
        >
          <div>
            <Button
              fullWidth={false}
              variant="text"
              disabled={!data.hasChildren}
              className={`min-w-[0] p-2 rounded-full rounded-full text-gray-500 ${
                data.id === viewFromPosition
                  ? 'bg-indigo-200 !text-indigo-600 hover:bg-indigo-300'
                  : ''
              }`}
              onClick={() =>
                viewFromPosition === 'ORG_NODE'
                  ? setViewFromPosition(data.id)
                  : setViewFromPosition('ORG_NODE')
              }
            >
              {/* // Show eye if no focus view or show eye-slash if in focus view
                and this is the node being focussed on */}
              {viewFromPosition === 'ORG_NODE' && (
                <EyeIcon className="w-5 h-5" />
              )}
              {data.id === viewFromPosition && (
                <EyeSlashIcon className="w-5 h-5" />
              )}
            </Button>
          </div>
        </Tooltip>
      )}
      {data.hasChildren && (
        <Tooltip
          placement={'left'}
          title={data.minimised ? 'Show Sub-Units' : 'Hide Sub-Units'}
        >
          <div>
            <Button
              fullWidth={false}
              variant="text"
              disabled={!data.hasChildren}
              className={`min-w-[0] p-2 rounded-full rounded-full text-gray-500 ${
                !data?.minimised
                  ? 'bg-indigo-200 !text-indigo-600 hover:bg-indigo-300'
                  : ''
              }`}
              onClick={() =>
                data.setMinimisedFunction(data.id, !data.minimised)
              }
            >
              <UsersIcon className="w-5 h-5" />
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default SeatNodeSubMenu

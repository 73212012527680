import * as React from 'react'
import { FC, useState } from 'react'

import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Avatar, Tooltip } from '@mui/material'

import { useAuth } from 'src/Providers'

import Button from '../Library/Button/Button'

interface SeatNodeProps {
  data: any // not typed
  description?: string
  responsibilities?: string[]
  measurables?: string[]
  fullView?: boolean
  matchedSearchTerm: boolean
  expandedNodes: any // not typed
  setExpandedNodes: any // not typed
  viewFromPosition: any // not typed
}

const SeatNode: FC<SeatNodeProps> = ({
  data,
  description,
  responsibilities,
  measurables,
  fullView,
  matchedSearchTerm,
  expandedNodes,
  setExpandedNodes,
  viewFromPosition,
}) => {
  const [individualFullView, setIndividualFullView] = useState<boolean>(false)

  const { currentUser } = useAuth()
  const clientLogomarkUrl = currentUser?.parentData?.logomarkUrl

  return (
    <div
      className={`p-6 py-10 bg-white border-2 border-gray-300 rounded-xl text-center relative cursor-auto w-[450px]
      ${matchedSearchTerm ? 'outline outline-8 outline-green-600 ' : ''}
      ${
        viewFromPosition === data?.id
          ? 'outline outline-8 outline-indigo-600 '
          : ''
      }
      `}
    >
      {data.type === 'seat' ? (
        <Avatar
          src={data?.person?.avatarUrl ?? clientLogomarkUrl}
          imgProps={{ crossOrigin: 'anonymous' }}
          className="w-20 h-20 absolute top-[-40px] left-[50%] translate-x-[-50%] border-2 border-gray-300"
        />
      ) : (
        <BuildingOfficeIcon className="w-20 h-20 absolute top-[-40px] left-[50%] translate-x-[-50%] bg-gray-400 text-white rounded-full p-2" />
      )}

      {data.type === 'ent' && (
        <>
          <p className="text-[32px] pt-6 font-bold text-gray-600 uppercase truncate">
            {data.position}
          </p>
          {data.description && (
            <p className="text-[18px] pt-6 text-gray-600 uppercase truncate">
              ABN: {data.description}
            </p>
          )}
        </>
      )}
      {data.type === 'seat' && (
        <>
          <p className="text-[26px] pt-6 font-bold text-gray-600 truncate">
            {data?.person?.name}
          </p>
          <p className="text-xl text-gray-400 truncate">{data?.position}</p>
        </>
      )}

      {(fullView || individualFullView) && data.type === 'seat' && (
        <div className="text-left max-w-[400px] max-h-[375px] overflow-y-scroll p-4 mt-6">
          {description && (
            <>
              <p className="text-gray-600">
                <b>Job Description</b>
              </p>
              <p className="text-gray-500">{description}</p>
            </>
          )}

          <br />
          {responsibilities.length > 0 && (
            <>
              <p className="text-gray-600">
                <b>Responsibilities</b>
              </p>
              <ul className="list-disc pl-4">
                {responsibilities.map((item) => (
                  <li key={item} className="text-gray-500">
                    {item}
                  </li>
                ))}
              </ul>
            </>
          )}
          <br />
          {measurables.length > 0 && (
            <>
              <p className="text-gray-600">
                <b>Measurables</b>
              </p>
              <ul className="list-disc pl-4">
                {measurables.map((item) => (
                  <li key={item} className="text-gray-500">
                    {item}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}

      {data.type === 'seat' && !fullView && (
        <div className=" absolute bottom-[-15px] left-[50%] translate-x-[-50%]">
          <Tooltip title={`${individualFullView ? 'Hide' : 'Show'} Details`}>
            <div>
              <Button
                fullWidth={false}
                variant="text"
                disabled={fullView}
                className="w-[100px] p-0 py-1 rounded-full text-gray-700 bg-gray-200 hover:bg-gray-300"
                onClick={() => {
                  if (individualFullView) {
                    if (expandedNodes.includes(data.id)) {
                      setExpandedNodes(
                        expandedNodes.filter((id) => id !== data.id),
                      )
                    }
                  } else {
                    if (!expandedNodes.includes(data.id)) {
                      setExpandedNodes([...expandedNodes, data.id])
                    }
                  }
                  setIndividualFullView(!individualFullView)
                }}
              >
                <ChevronDownIcon
                  className={`w-5 h-5 ${
                    individualFullView ? 'rotate-180' : ''
                  }`}
                />
              </Button>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default SeatNode

import * as React from 'react'
import { FC } from 'react'

import { BuildingOfficeIcon } from '@heroicons/react/24/outline'

interface OrganisationNodeProps {
  data: any // not typed
}

const OrganisationNode: FC<OrganisationNodeProps> = ({ data }) => {
  return (
    <div
      className={
        'p-6 py-10 bg-white border-2 border-gray-300 rounded-xl text-center relative cursor-auto w-[450px]'
      }
    >
      <BuildingOfficeIcon className="w-20 h-20 absolute top-[-40px] left-[50%] translate-x-[-50%] bg-gray-400 text-white rounded-full p-2" />

      <p className="text-[32px] pt-6 font-bold text-gray-600 uppercase">
        {data.position}
      </p>
    </div>
  )
}

export default OrganisationNode

import * as React from 'react'
import { useEffect, useState } from 'react'

import { BuildingOfficeIcon, UserPlusIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import { Handle, Position } from 'reactflow'

import OrganisationNode from 'src/components/InformerOrgChart/OrganisationNode'
import SeatNode from 'src/components/InformerOrgChart/SeatNode'
import SeatNodeSubMenu from 'src/components/InformerOrgChart/SeatNodeSubMenu'
import Button from 'src/components/Library/Button/Button'
import useOrgChartFlowStore from 'src/lib/stores/orgChartFlowStores'

// We use these states alot, allocating it to a function allows us to make the code cleaner
function useOrgChartFlowStoreData() {
  // Use Zustand for state management as we can't access local states from the react flow nodes
  const fullView = useOrgChartFlowStore((state) => state.fullView) // State fo if the whole node should be shown or just the small node
  const isAllowedToEdit = useOrgChartFlowStore((state) => state.isAllowedToEdit) // State of if the user is allowed to edit the structure
  const setIsAllowedToEdit = useOrgChartFlowStore(
    (state) => state.setIsAllowedToEdit,
  ) // Function to set the state of isAllowedToEdit

  const allPositions = useOrgChartFlowStore((state) => state.allPositions) // All positions/seats in the business, used when allowing the user to move a branch of nodes to report to a different position/seat
  const viewFromPosition = useOrgChartFlowStore(
    (state) => state.viewFromPosition,
  ) // The position/seat that the user has selected to view the structure from (focussed view)
  const setViewFromPosition = useOrgChartFlowStore(
    (state) => state.setViewFromPosition,
  ) // Function to set the viewFromPosition state
  const addSeatLoading = useOrgChartFlowStore((state) => state.addSeatLoading) // Boolean to show if the add seat button is loading
  const setEditNode = useOrgChartFlowStore((state) => state.setEditNode) // Function to set the state of editNode
  const searchText = useOrgChartFlowStore((state) => state.searchText) // The text that the user has entered into the search box
  const structureView = useOrgChartFlowStore((state) => state.structureView) // State of if the user is in structure view mode
  const setExpandedNodes = useOrgChartFlowStore(
    (state) => state.setExpandedNodes,
  ) // Function to set the expandedNodes state
  const expandedNodes = useOrgChartFlowStore((state) => state.expandedNodes) // State of the expanded nodes
  return {
    fullView,
    allPositions,
    viewFromPosition,
    setViewFromPosition,
    addSeatLoading,
    setEditNode,
    searchText,
    structureView,
    setExpandedNodes,
    expandedNodes,
    isAllowedToEdit,
    setIsAllowedToEdit,
  }
}

export interface ColourInfo {
  colour?: string
  tooltip?: string
}

function InformerOrgChartStructureNode({ data }) {
  const {
    fullView,
    allPositions,
    viewFromPosition,
    setViewFromPosition,
    addSeatLoading,
    setEditNode,
    searchText,
    structureView,
    setExpandedNodes,
    expandedNodes,
    isAllowedToEdit,
    setIsAllowedToEdit,
  } = useOrgChartFlowStoreData() // Grab the states from above

  const [anchorEl, setAnchorEl] = useState(null) // State for the anchor element of the popover
  const [description, setDescription] = useState('') // State for if the description is open
  const [responsibilities, setResponsibilities] = useState([]) // State for if the description is open
  const [measurables, setMeasurables] = useState([]) // State for if the description is open
  const [matchedSearchTerm, setMatchedSearchTerm] = useState<boolean>(false) // State for the border colour of the node
  const [selectedAnchorPosition, setSelectedAnchorPosition] = useState(
    data.reportsToId,
  ) // State for the selected position in the select box based ont the current position and the current user UI/UX
  const popoverOpen = Boolean(anchorEl) // Boolean for if the popover is open
  const popoverId: string = popoverOpen ? 'select-popover' : '' // Id for the popover

  useEffect(() => {
    setDescription(data.description)
    setResponsibilities(
      data.responsibilities ? JSON.parse(data.responsibilities) : [],
    )
    setMeasurables(data.measurables ? JSON.parse(data.measurables) : [])
  }, [data])

  const handleMoveToNewSupervisor = (event) => {
    setAnchorEl(event.currentTarget)
  } // Function to handle the click of the move to new supervisor button

  const handleClosePopover = () => {
    setAnchorEl(null)
  } // Function to handle the close of the popover

  const handleSelectChangePopover = (event) => {
    if (!event) {
      return
    }
    setSelectedAnchorPosition(event.id)
    handleClosePopover()
    data.updateSeatSuperiorFunction(data.id, event.id, isAllowedToEdit)
  } // Function to handle the change of the select box in the popover

  useEffect(() => {
    const searchTextToLower = searchText.toLowerCase()
    const memberPositionToLower = (data?.position ?? '').toLowerCase()
    const memberNameToLower = (data?.person?.name ?? '').toLowerCase()

    if (searchTextToLower === '') {
      setMatchedSearchTerm(false)
    } else {
      if (memberPositionToLower.includes(searchTextToLower)) {
        setMatchedSearchTerm(true)
      } else if (memberNameToLower.includes(searchTextToLower)) {
        setMatchedSearchTerm(true)
      } else {
        setMatchedSearchTerm(false)
      }
    }
  }, [searchText])

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectableStart={false}
      />
      <div className="group relative p-6 cursor-default">
        {data.type === 'org' && <OrganisationNode data={data} />}

        {(data.type === 'seat' || data.type === 'ent') && (
          <>
            <div className="hidden group-hover:block group-focus:block absolute top-[50%] translate-y-[-50%] left-0 z-10">
              <SeatNodeSubMenu
                data={data}
                structureView={structureView}
                allPositions={allPositions}
                handleMoveToNewSupervisor={handleMoveToNewSupervisor}
                handleSelectChangePopover={handleSelectChangePopover}
                setEditNode={setEditNode}
                selectedAnchorPosition={selectedAnchorPosition}
                anchorEl={anchorEl}
                handleClosePopover={handleClosePopover}
                popoverId={popoverId}
                popoverOpen={popoverOpen}
                viewFromPosition={viewFromPosition}
                setViewFromPosition={setViewFromPosition}
                isAllowedToEdit={isAllowedToEdit}
              />
            </div>
            <SeatNode
              data={data}
              viewFromPosition={viewFromPosition}
              matchedSearchTerm={matchedSearchTerm}
              description={description}
              responsibilities={responsibilities}
              measurables={measurables}
              fullView={fullView}
              expandedNodes={expandedNodes}
              setExpandedNodes={setExpandedNodes}
            />
          </>
        )}
      </div>
      {/* // Now for the bottom of the node where we show the add new seat and add
      new entity buttons */}

      <div className="flex gap-2 align-items-center justify-center">
        {structureView && (
          <>
            {/* // Always show the add new seat button */}
            <Tooltip title={'Add New Seat'}>
              <div>
                <Button
                  loading={addSeatLoading}
                  disabled={!isAllowedToEdit}
                  fullWidth={false}
                  variant="outlined"
                  className="min-w-[0] p-2 rounded-full bg-white text-gray-500 hover:bg-gray-100 !border-2 !border-gray-300"
                  onClick={() => {
                    data.addSeatToStructureFunction(data.id, 'seat', false)
                    setEditNode(null)
                  }}
                >
                  <UserPlusIcon className="w-8 h-8" />
                </Button>
              </div>
            </Tooltip>
            {/* // Entities can only sit below other entities or the organisation
        itself, so only show the add new entity button if the node is an org or
        entity */}
            {(data.type === 'org' || data.type === 'ent') && (
              <Tooltip title={'Add New Entity'}>
                <div>
                  <Button
                    loading={addSeatLoading}
                    fullWidth={false}
                    variant="outlined"
                    disabled={!isAllowedToEdit}
                    className="min-w-[0] p-2 rounded-full bg-white text-gray-500 hover:bg-gray-100 !border-2 !border-gray-300"
                    onClick={() => {
                      data.addSeatToStructureFunction(data.id, 'ent', false)
                      setEditNode(null)
                    }}
                  >
                    <BuildingOfficeIcon className="w-8 h-8" />
                  </Button>
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        isConnectableStart={false}
      />
    </>
  )
}

export default InformerOrgChartStructureNode
